
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class UserStatusBadge extends Vue {
  @Prop()
  public status!: string

  public get statusValue(): string {
    return this.status.replaceAll('_', ' ')
  }

  public statusList = {
    disabled: {
      color: 'danger',
    },
    expired: {
      color: 'warning',
    },
    active: {
      color: 'success',
    },
  }
}
